import {useState} from "react";
import {confirmEmailChange, logout} from "@/api/rest/authApi.ts";
import {BannerState} from "@/app/account/AccountPage.tsx";
import {clsx} from "clsx";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function ConfirmEmailChangeForm({className}: {className?: string}) {
    const { t } = useTranslation();
    const [changeResult, setChangeResult] = useState<BannerState | null>(null);

    const navigate = useNavigate();
    const { search} = useLocation();
    const token = new URLSearchParams(search).get('token');

    const confirmChange = async () => {
        setChangeResult(null);
        if (!token) {
            setChangeResult({type: "error", message: t("login.notAuthorized")});
            return;
        }
        const result = await confirmEmailChange({token: token});
        if (result.success) {
            setChangeResult({type: "success", message: t("login.emailChangeSuccessful", {newEmail: result.result.newEmail})});
        } else {
            setChangeResult({type: "error", message: result.message});
        }
    }

    const continueFlow = async () => {
        await logout()
        navigate('/login')
    }

    return (
        <div className={className}>
            <span className="text-gray-500 text-center">
                {t("login.confirmEmailChange")}
            </span>
            {changeResult && <span
                className={clsx(`rounded-lg p-4 text-center text-md`,
                    changeResult.type === "success" ? `bg-green-300 text-green-900` : `bg-red-300 text-red-900`
                )}>{changeResult.message}</span>}
            {changeResult?.type !== "success" && <button className="rounded-md p-2" onClick={confirmChange}>{t("login.confirm")}</button>}
            {changeResult?.type === "success" && <button className="rounded-md p-2" onClick={continueFlow}>{t("login.continue")}</button>}
        </div>
    )
}
