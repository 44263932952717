import {useCallback, useRef} from "react";
import {SparklesIcon} from "@heroicons/react/24/outline";
import {PaperAirplaneIcon} from "@heroicons/react/24/solid";
import {TypeAnimation} from "react-type-animation";
import {isMobileDevice} from "@/components/common/helper.ts";
import {useTranslation} from "react-i18next";

interface ChatFieldProps {
    submitQuestion: (startFresh: boolean, quesiton: string) => void,
}

export default function ChatField({submitQuestion}: ChatFieldProps) {
    const { t } = useTranslation()
    const outsideInputRef = useRef<HTMLInputElement>(null);

    const onSubmit = useCallback(() => {
        const value = outsideInputRef.current?.value ?? ""
        if (value.trim()) {
            submitQuestion(true, value);
            outsideInputRef.current && (outsideInputRef.current.value = '');
        }
    }, [submitQuestion]);

    const exampleQuestions = [
        t("chat.question1"),
        t("chat.question2"),
        t("chat.question3"),
        t("chat.question4"),
    ]

    return (
        <label className="flex flex-grow relative">
            <div className="flex flex-grow flex-row relative items-center">
                <PaperAirplaneIcon
                    className="w-6 h-6 inline mr-2 absolute right-0 text-gray-300 hover:text-dobu-orange cursor-pointer"
                    onClick={() => onSubmit()}
                />
                <SparklesIcon
                    className="w-6 h-6 inline ml-2 absolute left-0 text-dobu-orange md:text-gray-400 pointer-events-none"/>
                <input className="border-2 border-dobu-orange md:border-gray-300 rounded-md p-2 pl-9 flex-grow peer placeholder:opacity-0"
                       name='question' type="text" ref={outsideInputRef} placeholder={t("chat.talkToYourHome")}
                       onKeyUp={(e) => e.key === "Enter" && onSubmit()} autoFocus={!isMobileDevice()}/>
                <TypeAnimation
                    sequence={[
                        exampleQuestions[0],
                        3000,
                        exampleQuestions[1],
                        3000,
                        exampleQuestions[2],
                        3000,
                        exampleQuestions[3],
                        3000,
                    ]}
                    className="absolute ml-10 text-gray-400 pointer-events-none peer-focus:hidden peer-[:not(:placeholder-shown)]:hidden"
                    wrapper="span"
                    speed={25}
                    repeat={Infinity}
                />
            </div>
        </label>
    )
}
