import {FormEvent, useMemo, useState} from "react";
import {Cog6ToothIcon} from "@heroicons/react/24/outline";
import {
    AccountType,
    AssignAccountRequest,
    AssignAccountResponse,
    assignUser,
    HouseholdData,
    unassignUser
} from "@/api/rest/api.ts";
import AccountsTable from "@/components/AccountsTable.tsx";
import ManagedPopup from "@/components/common/ManagedPopup.tsx";
import {useTranslation} from "react-i18next";

interface FormValues {
    type: AccountType;
    registryCode: string;
    name?: string;
    email?: string;
}

export default function HouseholdSettingsPopup({householdData, updateHousehold}: {householdData: HouseholdData, updateHousehold: () => void}) {
    const [newAccount, setNewAccount] = useState<AssignAccountResponse | null>(null);
    const { t } = useTranslation();

    const unassignAccount = async (accountId: number) => {
        await unassignUser(householdData.household.id, accountId);
        updateHousehold();
    }

    const submitForm = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = Object.fromEntries((new FormData(e.currentTarget)).entries()) as unknown as FormValues;
        const data: AssignAccountRequest = {
            type: formData.type,
            registryCode: formData.registryCode,
            country: "EE",
        }
        if (formData.name && formData.email) {
            data.additional = {
                name: formData.name,
                email: formData.email,
            }
        }
        try {
            const result = await assignUser(householdData.household.id, data);
            if (result === AssignAccountResponse.SUCCESS) {
                updateHousehold();
                setNewAccount(null);
            } else {
                setNewAccount(result);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const householdMembers = useMemo(() => {
        return householdData.accounts.filter(account => account.id !== householdData.building.accountId);
    }, [householdData]);


    return (
        <ManagedPopup trigger={
            <Cog6ToothIcon className="w-12 h-12 rounded p-2 hover:bg-gray-300 text-gray-500"/>
        } title={t("settings.title") + ": " + householdData.household.title}>
            <div className="flex flex-col overflow-hidden m-4">
                <span className='text-2xl font-bold pb-4'>{t("settings.accesses")}</span>
                <AccountsTable accounts={householdMembers}
                               showAddAccount={newAccount === null}
                               addAccount={() => setNewAccount(AssignAccountResponse.SUCCESS)}
                               unassignAccount={unassignAccount}/>
            </div>
            {newAccount && <form className="flex flex-col overflow-hidden m-4" onSubmit={submitForm}>
                <span className='text-2xl font-bold pb-4'>{t("settings.addNewAccess")}</span>
                <div className="flex flex-row gap-4 pb-4 flex-wrap md:flex-nowrap">
                    <div className="flex flex-col flex-grow basis-full md:basis-1/2">
                        <label className="text-gray-500" htmlFor="type">{t("settings.type")}</label>
                        <select className="border border-gray-300 rounded-md p-2 w-full" name='type'
                                required>
                            <option value={AccountType.INDIVIDUAL}>{t("settings.individual")}</option>
                            <option value={AccountType.ORGANIZATION}>{t("settings.organization")}</option>
                        </select>
                    </div>
                    <div className="flex flex-col flex-grow basis-full md:basis-1/2">
                        <label className="text-gray-500" htmlFor="registryCode">{t("settings.personalCodeOrRegistryCode")}</label>
                        <input className="border border-gray-300 rounded-md p-2 w-full" name='registryCode'
                               type="number"
                               placeholder={t("settings.personalCodeOrRegistryCode")} required/>
                    </div>
                </div>
                {newAccount === AssignAccountResponse.MORE_INFO_REQUIRED &&
                    <div className="flex flex-row gap-4 pb-4 flex-wrap md:flex-nowrap">
                        <div className="flex flex-col flex-grow basis-full md:basis-1/2">
                            <label className="text-gray-500" htmlFor="name">{t("settings.name")}</label>
                            <input className="border border-gray-300 rounded-md p-2 w-full"
                                   name='name'
                                   type="text"
                                   placeholder={t("settings.name")} required/>
                        </div>
                        <div className="flex flex-col flex-grow basis-full md:basis-1/2">
                            <label className="text-gray-500" htmlFor="email">{t("settings.email")}</label>
                            <input className="border border-gray-300 rounded-md p-2 w-full"
                                   name='email'
                                   type="email"
                                   placeholder={t("settings.email")} required/>
                        </div>
                    </div>}
                <button className="rounded-md p-2 flex-grow">{t("settings.save")}</button>
            </form>}
        </ManagedPopup>
    )
}
