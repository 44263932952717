import {useCallback, useEffect, useState} from "react";
import {getCase, WarrantyCaseResponse} from "@/api/rest/warrantyApi.ts";

export const useWarrantyCase = (caseId: number) => {
    const [warrantyCase, setWarrantyCase] = useState<WarrantyCaseResponse | null>(null);

    const fetchData = useCallback(async () => {
        const data = await getCase(caseId);
        setWarrantyCase(data.success ? data.result : null);
    }, [caseId]);

    useEffect(() => {
        fetchData().then(null);
    }, [fetchData, caseId]);

    return [warrantyCase, fetchData] as const;
}
