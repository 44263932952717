import {apiBase, ApiResponse, fetchUsingApi} from "@/api/rest/api.ts";
import i18n from "@/i18n.tsx";

export interface DocumentRaw {
    id: number;
    hash: string;
    mimeType: string;
    originalFilename: string;
    processingStatus: string;
}

export interface Document extends DocumentRaw {
    folderPath: string[];
    fileName: string;
}

export const enrichDocument = (document: DocumentRaw): Document => {
    const rawPath = document.originalFilename.startsWith('/') ? document.originalFilename.slice(1) : document.originalFilename;
    const path = rawPath.split('/');
    return {
        ...document,
        folderPath: path.slice(0, path.length - 1),
        fileName: path[path.length - 1],
    }
}

export const uploadDocuments = async (householdId: number, files: File[]): Promise<ApiResponse<boolean>> => {
    const formData = new FormData();
    formData.append('householdId', householdId.toString());
    files.forEach(file => {
        formData.append('files', file);
        formData.append('fileNames', file.path);
    });
    return await fetchUsingApi(`${apiBase}/documents/v1/upload`, 'POST', (e) => e as boolean, formData);
}

export const downloadDocument2 = async (url: string, originalName: string | null) => {
    const result = await fetch(url, {
        method: 'GET',
        credentials: 'include',
    });
    if (result.status === 200) {
        const data = await result.blob()
        const url = URL.createObjectURL(data)
        const a = document.createElement("a")
        originalName && (a.download = originalName)
        a.target = "_blank"
        a.href = url
        a.click()
        return
    }
    throw i18n.t("common.error.500");
}

export const loadDocument = async (doc: Document): Promise<string> => {
    const result = await fetch(getDocumentUrl2(doc), {
        method: 'GET',
        credentials: 'include',
    });
    if (result.status === 200) {
        return await result.text();
    }
    throw i18n.t("common.error.500");
}

export const getDocumentUrl2 = (doc: Document | null, useHash = false, value?: string) => {
    if (!doc && !value) {
        throw new Error('Neither document nor custom value provided');
    }
    if (useHash) {
        return `${apiBase}/documents/v1/download/hash/${doc?.hash ?? value}`;
    }
    return `${apiBase}/documents/v1/download/id/${doc?.id ?? value}`;
}
