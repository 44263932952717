import {HouseholdData} from "@/api/rest/api.ts";
import {Document} from "@/api/rest/fileApi.ts";
import {ReactNode, useState} from "react";
import UnmanagedPopup from "@/components/common/UnmanagedPopup.tsx";
import DocumentsList from "@/components/household-contents/DocumentsList.tsx";

export default function AddFilesPopup({householdData, addDocument, children, triggerClassName}: {householdData: HouseholdData, addDocument: (doc: Document) => void, children: ReactNode, triggerClassName?: string}) {

    const [isOpen, setIsOpen] = useState(false);
    const submitDocument = (doc: Document) => {
        addDocument(doc)
        setIsOpen(false)
    };

    return (
        <UnmanagedPopup minHeight={false} title="Lisa dokumendid" isOpen={isOpen} closePopup={() => setIsOpen(false)} trigger={
            <span onClick={() => setIsOpen(true)} className={triggerClassName}>
                {children}
            </span>
        }>
            <div className="flex flex-col items-stretch overflow-y-auto gap-2 p-4">
                <DocumentsList documents={householdData.documents} buildingId={householdData.building.id} itemsClassName="bg-slate-100" allowDownload={false} onSelect={submitDocument}/>
            </div>
        </UnmanagedPopup>
    )
}
