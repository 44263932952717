/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import dayjs, {Dayjs} from "@/components/dayjsExtended.ts";
import {groupBy, keyBy} from "lodash";
import type {Router} from "@remix-run/router/dist/router";
import posthog from "posthog-js";
import * as Sentry from "@sentry/react";
import {Document, enrichDocument} from "@/api/rest/fileApi.ts";
import i18n from "../../i18n";

export const apiBase = import.meta.env.MODE === 'production' ? 'https://api.dobu.ee' : 'http://localhost:8080';
export let routerInstance: Router | null = null
export const setRouterInstance = (router: Router) => {
    routerInstance = router;
}

export type ApiResponse<Type> = {
    success: true;
    result: Type;
} | {
    success: false;
    message: string;
}

export interface HouseholdAccountRelation {
    householdId: number;
    accountId: number;
}

export interface Account {
    id: number;
    name: string;
    type: AccountType;
    country: string;
    registryCode: string;
}

export enum AccountType {
    ORGANIZATION = "ORGANIZATION",
    INDIVIDUAL = "INDIVIDUAL",
}

interface HouseholdOwnership {
    households: HouseholdRaw[];
    accounts: Account[];
    relations: HouseholdAccountRelation[];
}

export interface HouseholdRaw {
    id: number;
    buildingId: number;
    area: number;
    title: string;
    warrantyEndDate: string;
}

export interface Household extends HouseholdRaw {
    warrantyEndDateTimestamp: Dayjs;
}

export interface HouseholdWithOwnership extends HouseholdRaw {
    accounts: Account[];
}

const convertHouseholdOwnership = (data: HouseholdOwnership): HouseholdWithOwnership[] => {
    const accountsById = keyBy(data.accounts, (account) => account.id);
    const accountsByHousehold = groupBy(data.relations, (relation) => relation.householdId);
    return data.households.map((household) => {
        return {
            ...household,
            accounts: (accountsByHousehold[household.id] ?? []).map((relation) => accountsById[relation.accountId]),
        }
    });
}

export const fetchAllHouseholds = async (): Promise<ApiResponse<HouseholdWithOwnership[]>> => {
    return await fetchUsingApi(`${apiBase}/household/v1/list`, 'GET', convertHouseholdOwnership);
}

export interface Room {
    id: number;
    householdId: number;
    name: string;
    floor: number;
}

export interface ProductUpdateRequest {
    name: string;
    manufacturer: string | null;
    code: string | null;
    information: string | null;
    maintenanceIntervalDays: number | null;
    associatedRooms: number[];
    associatedDocuments: number[];
}

interface ProductRaw {
    id: number;
    householdId: number;
    name: string;
    manufacturer: string | null;
    code: string | null;
    information: string | null;
    createdAt: string;
    nextMaintenance: string;
    maintenanceIntervalDays: number | null;
    maintenanceName: string | null;
}

export interface Product extends ProductRaw {
    createdAtTimestamp: Dayjs;
    nextMaintenanceTimestamp: Dayjs | null;
}

export interface ProductRoomRelation {
    productId: number;
    roomId: number;
}

export interface DocumentProductRelation {
    productId: number;
    documentId: number;
}

export interface HouseholdData {
    building: Building;
    household: Household;
    rooms: Room[];
    products: Product[];
    documents: Document[];
    accounts: Account[];
    rpRelations: ProductRoomRelation[];
    dpRelations: DocumentProductRelation[];
}

export interface Maintenance {
    name: string;
    information: string;
    nextMaintenance: string;
    nextMaintenanceTimestamp: Dayjs;
    history: MaintenanceHistory[];
}

export interface MaintenanceHistoryRaw {
    performedAt: string;
    scheduledFor: string;
    document: Document | null;
}

export interface MaintenanceHistory extends MaintenanceHistoryRaw {
    performedAtTimestamp: Dayjs;
    scheduledForTimestamp: Dayjs;
}

export const fetchHouseholdData = async (householdId: number): Promise<ApiResponse<HouseholdData>> => {
    return await fetchUsingApi(`${apiBase}/household/v1/overview/${householdId}`, 'GET', convertHouseholdData);
}

export const fetchMaintenance = async (productId: number): Promise<ApiResponse<Maintenance>> => {
    return await fetchUsingApi(`${apiBase}/product/v1/${productId}/maintenance`, 'GET', convertMaintenanceData);
}

const enrichProduct = (product: ProductRaw): Product => {
    return {
        ...product,
        createdAtTimestamp: dayjs(product.createdAt),
        nextMaintenanceTimestamp: product.nextMaintenance ? dayjs(product.nextMaintenance) : null,
    }
}
const enrichMaintenanceHistory = (maintenanceHistory: MaintenanceHistoryRaw): MaintenanceHistory => {
    return {
        ...maintenanceHistory,
        performedAtTimestamp: dayjs(maintenanceHistory.performedAt),
        scheduledForTimestamp: dayjs(maintenanceHistory.scheduledFor),
        document: maintenanceHistory.document && enrichDocument(maintenanceHistory.document)
    }
}

const enrichHousehold = (household: HouseholdRaw): Household => {
    return {
        ...household,
        warrantyEndDateTimestamp: dayjs(household.warrantyEndDate),
    }
}

const convertHouseholdData = (data: HouseholdData): HouseholdData => {
    const products = data.products.map(enrichProduct);
    const documents = data.documents.map(enrichDocument);
    const household = enrichHousehold(data.household);
    return {
        ...data,
        household,
        products,
        documents
    }
}

const convertMaintenanceData = (data: Maintenance): Maintenance => {
    return {
        ...data,
        nextMaintenanceTimestamp: dayjs(data.nextMaintenance),
        history: data.history.map(enrichMaintenanceHistory)
    }
}

export const updateProduct = async (productId: number, data: ProductUpdateRequest): Promise<ApiResponse<Product>> => {
    return await fetchUsingApi(`${apiBase}/product/v1/${productId}/update`, 'POST', enrichProduct, JSON.stringify(data));
}

export const createProduct = async (householdId: number, data: ProductUpdateRequest): Promise<ApiResponse<Product>> => {
    return await fetchUsingApi(`${apiBase}/product/v1/create?householdId=${householdId}`, 'POST', enrichProduct, JSON.stringify(data));
}

export const deleteProduct = async (productId: number): Promise<ApiResponse<void>> => {
    return await fetchUsingApi(`${apiBase}/product/v1/${productId}/delete`, 'DELETE', () => undefined);
}

export const markMaintenanceDone = async (productId: number, file: File | null): Promise<ApiResponse<void>> => {
    const formData = new FormData();
    if (file) {
        formData.append('files', file);
        formData.append('fileNames', file.path);
    }
    return await fetchUsingApi(`${apiBase}/product/v1/${productId}/maintenance-done`, 'POST', () => undefined, formData);
}

export const fetchUsingApi = async <Type>(url: string, method: string, resultProcessingFunction: (result: any) => Type, body?: BodyInit | null | undefined): Promise<ApiResponse<Type>> => {
    try {
        const response = await fetch(url, {
            method,
            credentials: 'include',
            headers: body instanceof FormData ? {} : {
                "Content-Type": "application/json",
            },
            body
        });
        if (response.ok) {
            const json = await response.json();
            const result = resultProcessingFunction(json);
            return {
                success: true,
                result,
            }
        } else {
            if (response.status === 403) {
                routerInstance?.navigate('/login')
            }
            return {
                success: false,
                message: i18n.t(`common.error.${response.status}`) ?? i18n.t("common.error.500"),
            }
        }
    } catch (e) {
        return {
            success: false,
            message: String(e),
        }
    }
}

export interface AccountInfoRaw {
    id: number;
    email: string;
    name: string;
    createdAt: string;
    authorities: string[];
    language: string;
    accounts: AccountInfoAccount[];
}

export interface AccountInfo extends AccountInfoRaw {
    createdAtTimestamp: Dayjs;
}

export interface AccountInfoAccount extends Account {
    managedBuildings: Building[];
}

export interface Building {
    id: number;
    accountId: number;
    country: string;
    city: string;
    street: string;
    partnerCode: string | undefined;
    postcode: string;
}

const convertAccountInfo = (data: AccountInfoRaw): AccountInfo => {
    return {
        ...data,
        createdAtTimestamp: dayjs(data.createdAt),
    }
}

export const fetchAccountInfo = async (): Promise<ApiResponse<AccountInfo>> => {
    const result =  await fetchUsingApi(`${apiBase}/account/v1/info`, 'GET', convertAccountInfo);
    if (result.success) {
        try {
            Sentry.setUser({id: result.result.id.toString(), email: result.result.email, name: result.result.name});
            posthog.identify(result.result.id.toString(), {email: result.result.email, name: result.result.name});
            await i18n.changeLanguage(result.result.language);
        } catch (ignored) { /* empty */ }
    }
    return result
}

export interface AssignAccountRequest {
    type: AccountType;
    country: string;
    registryCode: string;
    additional?: AssignAccountRequestAdditional;
}

export interface AssignAccountRequestAdditional {
    name: string;
    email: string;
}

export enum AssignAccountResponse {
    SUCCESS = "SUCCESS",
    MORE_INFO_REQUIRED = "MORE_INFO_REQUIRED",
}

export const assignUser = async (householdId: number, assignRequest: AssignAccountRequest): Promise<AssignAccountResponse> => {
    const result = await fetch(`${apiBase}/household/v1/assign/${householdId}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(assignRequest)
    });
    if (result.status === 200) {
        return await result.json() as unknown as AssignAccountResponse;
    }
    throw i18n.t("common.error.500");
}

export const unassignUser = async (householdId: number, accountId: number): Promise<void> => {
    const result = await fetch(`${apiBase}/household/v1/assign/${householdId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            accountId
        })
    });
    if (result.status === 200) {
        return;
    }
    throw i18n.t("common.error.500");
}

export const createRoom = async (householdId: number, name: string): Promise<Room> => {
    const result = await fetch(`${apiBase}/room/v1/create`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            householdId,
            name
        })
    });
    if (result.status === 200) {
        return await result.json();
    }
    throw i18n.t("common.error.500");
}

export const deleteRoom = async (householdId: number, roomId: number): Promise<void> => {
    const result = await fetch(`${apiBase}/room/v1/remove`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            householdId,
            roomId
        })
    });
    if (result.status === 200) {
        return;
    }
    throw i18n.t("common.error.500");
}

export const deleteDocument = async (buildingId: number, householdId: number, documentId: number): Promise<void> => {
    const result = await fetch(`${apiBase}/buildings/${buildingId}/households/${householdId}/documents/${documentId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            "Content-Type": "application/json",
        },
    });
    if (result.status === 200) {
        return;
    }
    throw i18n.t("common.error.500");
}
