import {create} from "zustand";
import {AccountInfo, fetchAccountInfo} from "../rest/api.ts";
import {useEffect} from "react";

interface AccountState {
    account: AccountInfo | null;
}

interface AccountActions {
    load: () => Promise<void>;
    clear: () => void;
}

export const useAccountGlobal = create<AccountState & AccountActions>((set) => {
    return {
        account: null,
        load: async () => {
            const account = await fetchAccountInfo();
            if (account.success) {
                set({account: account.result});
            }
        },
        clear: () => {
            set({account: null});
        }
    }
});

export const useAccount = () => {
    const account = useAccountGlobal((state) => state.account);
    const load = useAccountGlobal((state) => state.load);

    useEffect(() => {
        if (account === null) {
            load().then(null)
        }
    }, [account, load]);

    return account;
}
