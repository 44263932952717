import Navbar from "@/components/Navbar";
import {Outlet} from "react-router-dom";


export default function LoggedInPage() {
    return (
        <div className="h-screen w-screen overflow-y-auto md:overflow-hidden flex flex-col md:flex-row justify-stretch">
            <Navbar/>
            <Outlet/>
        </div>
    )
}
