import {clsx} from "clsx";
import {ApiResponse} from "@/api/rest/api.ts";

export function ApiBanner({apiResponse, successMessage}: {
    apiResponse: ApiResponse<unknown>,
    successMessage: string,
}) {
    return (
        <Banner message={apiResponse.success ? successMessage : apiResponse.message} success={apiResponse.success} />
    )
}

export function Banner({message, success}: { message: string, success: boolean }) {
    return (
        <div className={clsx("p-2 text-center rounded-md", success ? "bg-green-200 text-green-900" : "bg-red-200 text-red-900")}>
            <span>
                {message}
            </span>
        </div>
    )
}
