import {ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import ManagedPopup from "@/components/common/ManagedPopup.tsx";
import {Document, getDocumentUrl2, loadDocument} from "@/api/rest/fileApi.ts";
import {isAppleMobileDevice} from "@/components/common/helper.ts";
import {useTranslation} from "react-i18next";
import {deleteDocument} from "@/api/rest/api.ts";
import {useDialogue} from "@/api/hooks/useDialogue.ts";

export default function DocumentsPreview({document, buildingId, householdId, className, onSelect, children, allowPreview, allowDelete = false, updateHousehold}: { document: Document, buildingId?: number, householdId?: number, className?: string, children: ReactNode, onSelect: (url: string, originalName: string) => void, allowPreview: boolean, allowDelete?: boolean, updateHousehold?: () => Promise<void>}) {
    const { t } = useTranslation();
    const type = document.fileName.split('.').pop();
    const supportedTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'png', 'jpg', 'jpeg', 'json'];
    const [, setIsOpen] = useDialogue();

    const useAppleMobileWorkaround = useMemo(() => {
        return isAppleMobileDevice()
    }, []);

    const removeDocument = async (documentId: number) => {
        if (allowDelete && buildingId && householdId) {
            await deleteDocument(buildingId, householdId, documentId);
            await updateHousehold?.();
            setIsOpen(false, true);
        }
    }

    if (!allowPreview || !supportedTypes.includes(type ?? "")) {
        return (
            <div
                className={className}
                onClick={() => onSelect(getDocumentUrl2(document), document.originalFilename)}>
                {children}
            </div>
        )
    }

    return (
        <ManagedPopup
            trigger={
                <div className={className} >
                    {children}
                </div>
            } title={
            <>
                <span className='break-all pr-3'>{document.fileName}</span>
                <a className="rounded text-sm leading-6 px-2 mx-2 bg-gray-500 text-slate-100 hover:bg-dobu-orange-focus focus-visible:bg-dobu-orange-focus cursor-pointer self-baseline"
                   onClick={() => onSelect(getDocumentUrl2(document), document.originalFilename)}>
                    {t("files.download")}
                </a>
                {allowDelete ? <button className="rounded text-sm leading-6 px-2 mx-2 bg-orange-100 text-slate-100 hover:bg-dobu-orange-focus focus-visible:bg-dobu-orange-focus cursor-pointer self-baseline"
                        onClick={() => removeDocument(document.id)}>{t("files.remove")}</button> : null}
                <span className="flex-grow"/>
            </>
        } minHeight={true}>
            {type == "pdf" && !useAppleMobileWorkaround && <embed
                src={getDocumentUrl2(document)}
                className="flex-grow"
            ></embed>}
            {type == "pdf" && useAppleMobileWorkaround && <embed
                src={'https://docs.google.com/gview?embedded=true&url=' + getDocumentUrl2(document, true)}
                className="flex-grow"
            ></embed>}
            {['doc', 'docx', 'xls', 'xlsx'].includes(type ?? '') && <embed
                src={'https://view.officeapps.live.com/op/embed.aspx?src=' + getDocumentUrl2(document, true)}
                className="flex-grow"
            ></embed>}
            {['png', 'jpg', 'jpeg'].includes(type ?? '') && <img
                src={getDocumentUrl2(document)}
                className="flex-grow object-contain"/>}
            {type == "json" && <JSONViewer doc={document}/>}
        </ManagedPopup>
    )
}

const JSONViewer = ({doc}: {doc: Document}) => {
    const [json, setJson] = useState<string>();
    useEffect(() => {
        loadDocument(doc).then((res) => {
            setJson(res)
        })
    }, [doc]);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parseResult = useMemo(() => {
        if (!json) return null;
        try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return JSON.parse(json);
        } catch (e) {
            return null;
        }
    }, [json]);

    const itemPreview = useCallback((item: unknown, index: number) => {
        return (
            <div key={index} className="bg-slate-100 p-2 rounded">
                {Object.entries(item ?? {}).map(([key, value]) => (
                    <div key={key}>
                        <span className="font-bold">{key.charAt(0).toUpperCase() + key.slice(1)}: </span>
                        <span className="text-wrap">{value}</span>
                    </div>
                ))}
            </div>
        )
    }, [])

    return (
        <div className="flex flex-col gap-2 p-4 overflow-y-auto scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100">
            {!parseResult && !json && <div>Loading...</div>}
            {!parseResult && json && <div>{json}</div>}
            {parseResult && ((parseResult instanceof Array) ? parseResult.map((item, index) => itemPreview(item, index)) : itemPreview(parseResult, 0)) }
        </div>
    )
}
