import {FormEvent, useEffect, useMemo, useState} from "react";
import {ChevronLeftIcon, DocumentTextIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {clsx} from "clsx";
import {useNavigate, useParams,} from "react-router-dom";
import {useHousehold} from "@/api/hooks/useHousehold.ts";
import {
    ApiResponse,
    createProduct,
    deleteProduct,
    Product,
    ProductUpdateRequest,
    updateProduct
} from "@/api/rest/api.ts";
import {Document} from "@/api/rest/fileApi.ts";
import {ApiBanner} from "@/components/common/Banner.tsx";
import AddFilesPopup from "@/components/household-contents/AddFilesPopup.tsx";
import {PlusIcon} from "@heroicons/react/24/solid";
import {useTranslation} from "react-i18next";
import {useMaintenance} from "@/api/hooks/useMaintenance.ts";

export default function ProductEdit({mode}: { mode: 'update' | 'create'}) {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const {householdId, productId} = useParams<{ householdId: string, productId: string }>()
    const [householdData, fetchHusehold] = useHousehold(Number(householdId))
    const product = useMemo(() => {
        if (!householdData) return null
        return householdData.products.find(product => product.id === Number(productId)) ?? null;
    }, [householdData, productId]);

    const [associatedRooms, setAssociatedRooms] = useState(new Set<number>());
    useEffect(() => {
        if (!householdData || !product) return
        const rooms = householdData.rpRelations.filter(relation => relation.productId === product.id)
            .map(relation => relation.roomId)
        setAssociatedRooms(new Set(rooms))
    }, [householdData, product]);

    const [associatedDocuments, setAssociatedDocuments] = useState<Document[]>([]);
    useEffect(() => {
        if (!householdData || !product) return
        const documents = householdData.dpRelations.filter(dp => dp.productId === product.id)
            .map(dp => householdData.documents.find(d => d.id === dp.documentId))
            .filter(d => d != undefined) as Document[]
        setAssociatedDocuments(documents);
    }, [householdData, product]);

    const [updateResult, setUpdateResult] = useState<ApiResponse<Product> | null>(null);
    const submitForm = async (e: FormEvent<HTMLFormElement>) => {
        setUpdateResult(null);
        e.preventDefault();
        const formData = Object.fromEntries((new FormData(e.currentTarget)).entries()) as unknown as Omit<ProductUpdateRequest, 'associatedRooms'>; // TODO: proper form validation
        const updateRequest: ProductUpdateRequest = {
            ...formData,
            associatedRooms: Array.from(associatedRooms),
            associatedDocuments: associatedDocuments.map(d => d.id),
        }
        if (mode == 'create') {
            const result = await createProduct(Number(householdId), updateRequest);
            if (result.success) {
                await fetchHusehold()
                navigate(`/household/${householdId}/product/${result.result.id}`)
            } else {
                setUpdateResult(result);
            }
        } else {
            const result = await updateProduct(Number(productId), updateRequest);
            setUpdateResult(result);
        }
    }

    const [maintenance] = useMaintenance(product?.id || null)

    if (!householdData) {
        return <div>Loading...</div>
    } else if (!product && mode == 'update') {
        return <div>Product not found</div>
    }

    return (
        <main
            className="flex flex-grow flex-col p-4 md:p-12 md:overflow-y-auto scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-white items-center">
            <form className="flex flex-grow self-stretch flex-col" onSubmit={submitForm}>
                <div className="flex flex-row ">
                    <ChevronLeftIcon
                        className="h-9 w-9 mr-2 hover:bg-slate-300 focus-visible:bg-dobu-orange cursor-pointer rounded"
                        onClick={() => navigate(`/household/${householdId}`)}/>
                    <h2 className="text-3xl font-bold pb-6 text-center">{mode == 'create' ? t("product.addNewProduct") : t("product.changeProduct")}</h2>
                </div>
                <div className="flex flex-row gap-4 pb-4">
                    <div className="flex flex-col flex-grow">
                        <label className="text-gray-500" htmlFor="name">{t("product.name")}</label>
                        <input className="border border-gray-300 rounded-md p-2 w-full" name='name' type="text"
                               defaultValue={product?.name}
                               placeholder={t("product.name")} required/>
                    </div>
                </div>
                <div className="flex flex-row flex-wrap md:flex-nowrap gap-4 pb-4">
                    <div className="flex flex-col flex-grow basis-1/2">
                        <label className="text-gray-500" htmlFor="manufacturer">{t("product.manufacturer")}</label>
                        <input className="border border-gray-300 rounded-md p-2 w-full" name='manufacturer' type="text"
                               defaultValue={product?.manufacturer ?? ''}
                               placeholder={t("product.manufacturer")}/>
                    </div>
                    <div className="flex flex-col flex-grow basis-1/2">
                        <label className="text-gray-500" htmlFor="code">{t("product.productNumber")}</label>
                        <input className="border border-gray-300 rounded-md p-2 w-full" name='code' type="text"
                               defaultValue={product?.code ?? ''}
                               placeholder={t("product.productNumber")}/>
                    </div>
                </div>
                <div className="flex flex-row gap-4 pb-4">
                    <div className="flex flex-col flex-grow">
                        <label className="text-gray-500" htmlFor="information">{t("product.extraInfo")}</label>
                        <textarea className="border border-gray-300 rounded-md p-2 w-full" name='information' rows={3}
                                  defaultValue={product?.information ?? ''}
                                  placeholder={t("product.extraInfoAboutTheProduct")}/>
                    </div>
                </div>
                <div className="flex flex-row flex-wrap md:flex-nowrap gap-6 pb-4">
                    <div className="flex flex-col flex-grow basis-1/2">
                        <label className="text-gray-500"
                               htmlFor="maintenanceIntervalDays">Hoolduse nimi</label>
                        <div className="flex flex-row items-baseline relative">
                            <input className="border border-gray-300 rounded-md p-2 w-full" name='maintenanceName'
                                   type="text"
                                   defaultValue={product?.maintenanceName ?? ''}
                                   placeholder="Hoolduse nimi"/>
                        </div>
                    </div>
                    <div className="flex flex-col flex-grow basis-1/2">
                        <label className="text-gray-500"
                               htmlFor="maintenanceIntervalDays">{t("product.maintenanceInterval")}</label>
                        <div className="flex flex-row items-baseline relative">
                            <input className="border border-gray-300 rounded-md p-2 w-full"
                                   name='maintenanceIntervalDays' type="number"
                                   defaultValue={product?.maintenanceIntervalDays ?? ''}
                                   placeholder="180"/>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row gap-4 pb-4">
                    <div className="flex flex-col flex-grow">
                        <label className="text-gray-500" htmlFor="information">Hoolduse info</label>
                        <textarea className="border border-gray-300 rounded-md p-2 w-full" name='maintenanceInformation' rows={3}
                                  defaultValue={maintenance?.information ?? ''}
                                  placeholder="Hoolduse info"/>
                    </div>
                </div>
                <div className="flex self-stretch flex-col pt-12">
                    <div className="flex flex-row flex-grow">
                        <h2 className="text-3xl font-bold pb-6 text-center">{t("product.relatedRooms")}</h2>
                    </div>
                    <div className="flex flex-row flex-wrap gap-2 pb-8">
                        {householdData.rooms.map(room => (
                            <div key={room.id} onClick={() => {
                                associatedRooms.has(room.id) ? associatedRooms.delete(room.id) : associatedRooms.add(room.id)
                                setAssociatedRooms(new Set(associatedRooms))
                            }}
                                 className={clsx("flex flex-col p-4 flex-shrink-0 cursor-pointer rounded-md",
                                     associatedRooms.has(room.id) ? "bg-dobu-orange hover:bg-dobu-orange-focus text-white" : "bg-white hover:bg-orange-100")}>
                                <span className="font-semibold">{room.name}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex self-stretch flex-col pt-12">
                    <div className="flex flex-row flex-grow">
                        <h2 className="text-3xl font-bold pb-6 text-center">{t("product.relatedDocuments")}</h2>
                    </div>
                    <div className="flex flex-row flex-wrap gap-2 pb-8">
                        {associatedDocuments.map(document => (
                            <div key={document.id}
                                 className={clsx("flex flex-row p-4 flex-shrink-0 rounded-md bg-white items-center group")}>
                                <span className="cursor-pointer">
                                    <DocumentTextIcon className="h-5 w-5 -mt-0.5 mr-2 inline group-hover:hidden"/>
                                    <XMarkIcon
                                        className="h-5 w-5 -mt-0.5 mr-2 text-gray-500 hover:text-red-700 hidden group-hover:inline"
                                        onClick={() => {
                                            setAssociatedDocuments(associatedDocuments.filter(d => d.id !== document.id))
                                        }}/>
                                </span>
                                <span className="font-semibold">{document.fileName}</span>
                            </div>
                        ))}
                        <AddFilesPopup householdData={householdData} addDocument={(doc) => {
                            if (associatedDocuments.find(d => d.id === doc.id)) return
                            setAssociatedDocuments([...associatedDocuments, doc])
                        }}
                                       triggerClassName="flex flex-row p-4 flex-shrink-0 items-center rounded-md bg-white hover:bg-orange-100 cursor-pointer">
                            <PlusIcon className="h-5 w-5 mr-2 inline"/>
                            <span className="font-semibold">{t("product.addDocuments")}</span>
                        </AddFilesPopup>
                    </div>
                </div>
                <div className="flex flex-grow"></div>
                <div className="flex flex-row flex-wrap md:flex-nowrap gap-4 pb-4">
                    <div className="flex flex-col flex-grow basis-1/2">
                        {updateResult &&
                            <ApiBanner apiResponse={updateResult}
                                       successMessage={t("product.productSuccessfullyChanged")}/>}
                        {!updateResult && mode == 'update' && <button
                            className="flex-grow rounded-md p-2 !bg-red-700 hover:!bg-red-800" onClick={async (e) => {
                            e.preventDefault()
                            await deleteProduct(Number(productId))
                            await fetchHusehold()
                            navigate(`/household/${householdId}`)
                        }}>{t("product.delete")}</button>}
                    </div>
                    <button
                        className="flex-grow basis-1/2 rounded-md p-2">{mode == 'create' ? t("product.addProduct") : t("product.saveChanges")}</button>
                </div>
            </form>
        </main>
    )
}
