import {useMemo} from "react";
import {Disclosure, Transition} from "@headlessui/react";
import {groupBy} from "lodash";
import {clsx} from "clsx";
import dayjs from "@/components/dayjsExtended.ts";
import {useHousehold} from "@/api/hooks/useHousehold.ts";
import {Product, Room} from "@/api/rest/api.ts";
import {Document} from "@/api/rest/fileApi.ts";
import ProductButton from "../../components/household-contents/ProductButton.tsx";
import ChatPopup from "../../components/chat/ChatPopup.tsx";
import HouseholdSettingsPopup from "@/components/household-contents/HouseholdSettingsPopup.tsx";
import RoomEditPopup from "@/components/household-contents/RoomEditPopup.tsx";
import {useNavigate, useParams} from "react-router-dom";
import DocumentsList from "@/components/household-contents/DocumentsList.tsx";
import {InformationCircleIcon, Squares2X2Icon, CubeIcon, CubeTransparentIcon} from "@heroicons/react/24/outline";
import {useAccount} from "@/api/hooks/useAccount.ts";
import UploadHouseholdFilesPopup from "@/components/household-contents/UploadHouseholdFilesPopup.tsx";
import {Popover} from "@/components/common/Popover.tsx";
import {useTranslation} from "react-i18next";
import MaintenanceButton from "@/components/household-contents/MaintenanceButton.tsx";

export default function Household() {
    const navigate = useNavigate()
    const account = useAccount()
    const {householdId} = useParams<{ householdId: string }>()
    const [householdData, updateHousehold] = useHousehold(Number(householdId))

    const isMangedBuilding = useMemo(() => {
        return (account?.accounts ?? []).flatMap(it => it.managedBuildings).some(it => it.id === householdData?.building.id) ?? false
    }, [account, householdData]);
    const hasMultipleFloors = useMemo(() => {
        return new Set(householdData?.rooms.map(room => room.floor)).size > 1;
    }, [householdData]);
    const maintenanceProducts = useMemo(() => {
        if (!householdData) return []
        return householdData.products.filter(product => product.nextMaintenanceTimestamp)
            .toSorted((a, b) => a.nextMaintenanceTimestamp!.diff(b.nextMaintenanceTimestamp))
    }, [householdData]);
    const productsByRoom = useMemo(() => {
        if (!householdData) return {}
        const productsByRooms = householdData.rpRelations.map(relation => ({
            ...relation,
            product: householdData.products.find(product => product.id === relation.productId)!
        }))
        const productIdsWithRooms = new Set(productsByRooms.map(it => it.productId))
        const productsWithoutRooms = householdData.products.filter(product => !productIdsWithRooms.has(product.id))
            .map(product => ({roomId: -1, productId: product.id, product}))
        return groupBy([...productsByRooms, ...productsWithoutRooms], product => product.roomId)
    }, [householdData]);
    const documentsByProduct = useMemo(() => {
        if (!householdData) return {}
        const documentsByProducts = householdData.dpRelations.map(relation => ({
            ...relation,
            document: householdData.documents.find(document => document.id === relation.documentId)!
        }))
        return groupBy(documentsByProducts, product => product.productId)
    }, [householdData]);
    const { t } = useTranslation();

    if (!householdData) {
        return <div>Loading...</div>
    }

    const next7Days = dayjs().add(7, 'days')

    return (
        <main
            className="flex flex-grow flex-col pt-12 p-4 md:p-12 md:overflow-y-auto scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-white">
            <div className="flex flex-row pb-8 justify-between">
                <div className="flex flex-col">
                    <h1 className="text-3xl font-bold">{householdData.household.title}</h1>
                    <h2 className="text-2xl text-gray-700">{householdData.building.street}</h2>
                    <h3 className="text-lg text-gray-500">{householdData.building.city}, {householdData.building.country}, {householdData.building.postcode}</h3>
                </div>
                <HouseholdSettingsPopup householdData={householdData} updateHousehold={updateHousehold}/>
            </div>
            <div className="flex flex-col pb-8">
                <span className='pb-6 flex flex-row items-center'>
                    <h2 className="text-2xl font-bold inline">{t("household.maintenanceActivities")}</h2>
                    <Popover target={<InformationCircleIcon className='ml-2 w-5 h-5 inline text-gray-500 hover:text-gray-800'/>}>
                        <span>{t("household.maintenanceInfo")}</span>
                    </Popover>
                </span>
                <div className="overflow-x-auto pb-4 scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-white">
                    <div className="flex flex-row gap-2">
                        {maintenanceProducts.map(product => (
                            <>
                                <MaintenanceButton
                                    key={product.id}
                                    className={clsx(
                                        "flex flex-col p-4 flex-shrink-0 rounded-lg border-2 hover:bg-orange-100",
                                        product.nextMaintenanceTimestamp!.isBefore(next7Days) ? product.nextMaintenanceTimestamp!.isBefore(dayjs()) ? 'border-red-500 bg-red-50' : 'border-yellow-500 bg-yellow-50' : 'bg-white border-gray-300'
                                    )}
                                    product={product}
                                    documents={documentsByProduct[product.id]?.map(it => it.document)?.toSorted((a, b) => b.fileName.length - a.fileName.length) ?? []}
                                    updateHousehold={updateHousehold}
                                >
                                    <div className="flex flex-row items-center">
                                        <span className="font-semibold">{product.maintenanceName}</span>
                                    </div>
                                    <span>{product.nextMaintenanceTimestamp!.fromNow()}</span>
                                </MaintenanceButton>
                            </>
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex flex-col pb-12">
                <h2 className="text-2xl font-bold pb-6">{t("household.actions")}</h2>
                <div className="flex flex-row gap-2 flex-wrap">
                    <ChatPopup householdId={Number(householdId)} documents={householdData.documents} className="flex flex-grow basis-full lg:basis-0"/>
                    {isMangedBuilding && <UploadHouseholdFilesPopup householdId={Number(householdId)} updateHousehold={updateHousehold} />}
                    <button className="rounded-md py-2 px-4 flex-grow lg:flex-grow-0" onClick={() => navigate(`/household/${householdId}/warranty`)}>{t("household.warrantyCases")}</button>
                </div>
            </div>
            <div className="flex flex-col pb-12">
                <h2 className="text-2xl font-bold pb-6 group">
                    {t("household.roomsAndProducts")}
                    <span onClick={() => navigate(`/household/${householdId}/product/new`)}
                          className="pl-4 font-semibold text-sm text-gray-500 hover:text-dobu-orange-focus cursor-pointer">{t("product.addProduct")}</span>
                    <RoomEditPopup householdData={householdData} updateHousehold={updateHousehold}/>
                </h2>
                <div className="flex flex-col gap-2">
                    {hasMultipleFloors ?
                        Object.entries(groupBy(householdData.rooms, room => room.floor)).map(([floor, rooms]) => {
                            if (rooms.every(room => (productsByRoom[room.id]?.length ?? 0) === 0)) return null;
                            return <Floor floor={floor} rooms={rooms} productsByRoom={productsByRoom}
                                          documentsByProduct={documentsByProduct}
                                          updateHousehold={updateHousehold}/>
                        }) : (householdData.rooms.map(room => (
                            <HouseholdProducts
                                key={room.id}
                                roomName={room.name}
                                products={productsByRoom[room.id]?.map(it => it.product) ?? []}
                                documentsByProduct={documentsByProduct}
                                updateHousehold={updateHousehold}
                            />
                        )))
                    }
                    <HouseholdProducts
                        key={-1}
                        roomName={t("household.productsWithoutRoom")}
                        products={productsByRoom[-1]?.map(it => it.product) ?? []}
                        documentsByProduct={documentsByProduct}
                        hasMultipleFloors={false}
                        isProductsWithoutRoom={true}
                        updateHousehold={updateHousehold}
                    />
                </div>
            </div>
            <div className="flex flex-col">
                <h2 className="text-2xl font-bold pb-6">{t("household.documents")}</h2>
                <DocumentsList documents={householdData.documents} buildingId={householdData.building.id} householdId={householdData.household.id} allowPreview={true} allowDelete={isMangedBuilding} updateHousehold={updateHousehold}/>
            </div>
        </main>
    )
}

const Floor = (
    {
        floor,
        rooms,
        productsByRoom,
        documentsByProduct,
        updateHousehold
    }: {
        floor: string,
        rooms: Room[],
        productsByRoom: Record<number, { product: Product }[]>,
        documentsByProduct: Record<number, { document: Document }[]>,
        updateHousehold: () => void
    }) => {
    const {t} = useTranslation();

    return (<Disclosure defaultOpen key={floor}>
            <Disclosure.Button
                className="py-2 px-4 font-semibold text-md rounded-md overflow-clip bg-white hover:bg-orange-100 text-start">
                <Squares2X2Icon className="h-5 w-5 -mt-0.5 mr-3 inline "/>
                {floor}. {t("household.floor")}
            </Disclosure.Button>
            <Transition
                enter="transition duration-150 ease-in"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition duration-150 ease-out"
                leaveFrom="opacity-100"
                leaveTo=" opacity-0"
            >
                <Disclosure.Panel className="flex flex-col gap-2">
                    {rooms.map(room => (
                        <HouseholdProducts
                            key={room.id}
                            roomName={room.name}
                            products={productsByRoom[room.id]?.map(it => it.product) ?? []}
                            documentsByProduct={documentsByProduct}
                            hasMultipleFloors={true}
                            updateHousehold={updateHousehold}
                        />
                    ))}
                </Disclosure.Panel>
            </Transition>
        </Disclosure>
    )
}

const HouseholdProducts = (
    {
        roomName,
        products,
        documentsByProduct,
        updateHousehold,
        hasMultipleFloors = false,
        isProductsWithoutRoom = false,
    }: {
        roomName: string,
        products: Product[],
        documentsByProduct: Record<number, { document: Document }[]>,
        updateHousehold: () => void,
        hasMultipleFloors?: boolean,
        isProductsWithoutRoom?: boolean
    }
) => {
    const { t } = useTranslation();
    if (products.length === 0) return <></>
    return (
        <Disclosure>
            <Disclosure.Button
                className={`${hasMultipleFloors ? "ml-6" : ""} py-2 px-4 font-semibold text-md rounded-md overflow-clip bg-white hover:bg-orange-100 text-start`}>
                {isProductsWithoutRoom ? <CubeTransparentIcon className="h-5 w-5 -mt-0.5 mr-3 inline "/> : <CubeIcon className="h-5 w-5 -mt-0.5 mr-3 inline "/>}
                {roomName}
                <span className="text-gray-400 text-sm ml-2">({products.length} {t("household.products")})</span>
            </Disclosure.Button>

            <Transition
                enter="transition duration-150 ease-in"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition duration-150 ease-out"
                leaveFrom="opacity-100"
                leaveTo=" opacity-0"
            >
                <Disclosure.Panel className={`${hasMultipleFloors ? "ml-6" : ""} flex flex-col gap-2`}>
                    {products.map(product => (
                        <ProductButton
                            key={product.id}
                            className="bg-white hover:bg-orange-100 cursor-pointer rounded-md py-2 px-4 ml-6"
                            product={product}
                            documents={documentsByProduct[product.id]?.map(it => it.document)?.toSorted((a,b) => b.fileName.length - a.fileName.length) ?? []}
                            updateHousehold={updateHousehold}
                        >
                            <span className="">{product.name}</span>
                            {product.manufacturer && <span className="ml-2 text-xs font-bold text-gray-400">({product.manufacturer})</span>}
                        </ProductButton>
                    ))}
                </Disclosure.Panel>
            </Transition>
        </Disclosure>
    )
}
