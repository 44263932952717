import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './i18n';
import Login from "./app/login/LoginPage.tsx";
import LoggedInPage from "./app/household/LoggedInPage.tsx";
import Household from "./app/household/HouseholdPage.tsx";
import ProductEdit from "./app/household/product/ProductEditPage.tsx";
import * as Sentry from "@sentry/react";
import {AccountPage} from "@/app/account/AccountPage.tsx";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {AuthType} from "@/app/login/LoginType.ts";
import WarrantyPage from "@/app/household/warranty/WarrantyPage.tsx";
import WarrantyCasePage from "@/app/warranty/WarrantyCasePage.tsx";
import HomePage from "@/app/household/HomePage.tsx";
import {setRouterInstance} from "@/api/rest/api.ts";
import {PostHogProvider} from "posthog-js/react";
import BuildingPage from "@/app/building/BuildingPage.tsx";
import BuildingSettingsPage from "@/app/building/BuildingSettingsPage.tsx";
import {GoogleOAuthProvider} from "@react-oauth/google";

Sentry.init({
    dsn: import.meta.env.MODE == 'production' ? import.meta.env.VITE_SENTRY_DSN as string : undefined,
    enabled: import.meta.env.MODE == 'production',
    integrations: [
        Sentry.browserTracingIntegration(),
        // Sentry.replayIntegration({
        //     maskAllText: false,
        //     blockAllMedia: false,
        // }),
    ],
    // Tracing
    tracePropagationTargets: ['api.dobu.ee'],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
});

const router = createBrowserRouter([
    { path: "/", element: <Login authType={AuthType.LOGIN} /> },
    {
        path: "/login",
        element: <Login authType={AuthType.LOGIN} />
    },
    {
        path: "/login/about",
        element: <Login authType={AuthType.ABOUT} />
    },
    {
        path: "/reset-password",
        element: <Login authType={AuthType.RESET_PASSWORD} />
    },
    {
        path: "/forgot-password",
        element: <Login authType={AuthType.FORGOT_PASSWORD} />
    },
    {
        path: "/change-email",
        element: <Login authType={AuthType.CHANGE_EMAIL} />
    },
    {
        path: "/household/",
        element: <LoggedInPage/>,
        children: [
            {
                path: "",
                element: <HomePage/>
            },
            {
                path: ":householdId",
                element: <Household/>
            },
            {
                path: ":householdId/product/:productId",
                element: <ProductEdit mode={'update'}/>
            },
            {
                path: ":householdId/product/new",
                element: <ProductEdit mode={'create'}/>
            },
            {
                path: ":householdId/warranty",
                element: <WarrantyPage/>
            },
        ],
    },
    {
        path: "/warranty/",
        element: <LoggedInPage/>,
        children: [
            {
                path: ":warrantyCaseId",
                element: <WarrantyCasePage/>
            },
        ],
    },
    {
        path: "/building/",
        element: <LoggedInPage/>,
        children: [
            {
                path: ":buildingId",
                element: <BuildingPage/>
            },
            {
                path: ":buildingId/settings",
                element: <BuildingSettingsPage/>
            },
        ],
    },
    {
        path: "/account/",
        element: <LoggedInPage/>,
        children: [
            {
                path: "",
                element: <AccountPage/>
            },
        ],
    },
]);

setRouterInstance(router);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
      <PostHogProvider apiKey={import.meta.env.VITE_POSTHOG_KEY as string} options={{api_host: "https://eu.posthog.com"}} >
          <GoogleOAuthProvider clientId="940412315029-s3cekbc4eancad0ero1jnf4p7142umq9.apps.googleusercontent.com">
              <RouterProvider router={router} />
          </GoogleOAuthProvider>
      </PostHogProvider>
  </React.StrictMode>,
)
