import {apiBase, ApiResponse, fetchUsingApi, routerInstance} from "@/api/rest/api.ts";
import {useAccountGlobal} from "@/api/hooks/useAccount.ts";
import {useHouseholdsGlobal} from "@/api/hooks/useHouseholds.ts";
import i18n from "../../i18n";

export interface LoginRequest {
    email: string;
    password: string;
}

export interface GoogleLoginRequest {
    credential: string;
}

export const login = async (request: LoginRequest): Promise<boolean> => {
    let result;
    try {
        result = await fetch(`${apiBase}/auth/login`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request)
        })
    } catch (e) {
        console.log('2', e)
        throw i18n.t("common.error.500");
    }
    console.log('result', result)
    if (result.status === 200) {
        return true;
    } else if (result.status === 403) {
        throw i18n.t("login.loginError")
    }
    throw i18n.t("common.error.500");
}

export const googleLogin = async (request: GoogleLoginRequest): Promise<boolean> => {
    let result;
    try {
        result = await fetch(`${apiBase}/auth/google`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request)
        })
    } catch (e) {
        console.log('2', e)
        throw i18n.t("common.error.500");
    }
    console.log('result', result)
    if (result.status === 200) {
        return true;
    } else if (result.status === 403) {
        throw i18n.t("login.loginError")
    }
    throw i18n.t("common.error.500");
}

export const logout = async (): Promise<boolean> => {
    try {
        await fetch(`${apiBase}/auth/logout`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
        })
        routerInstance?.navigate('/login')
        useAccountGlobal.getState().clear()
        useHouseholdsGlobal.getState().clear()
        return true
    } catch (e) { /* empty */ }
    return false
}

export interface ChangePasswordRequest {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export const changePassword = async (request: ChangePasswordRequest): Promise<boolean> => {
    let result;
    try {
        result = await fetch(`${apiBase}/auth/change-password`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request)
        })
    } catch (e) {
        throw i18n.t("common.error.500");
    }
    if (result.status === 200) {
        return true;
    }
    throw i18n.t("common.error.500");
}

export interface ForgotPasswordRequest {
    email: string;
}

export const forgotPassword = async (request: ForgotPasswordRequest): Promise<boolean> => {
    let result;
    try {
        result = await fetch(`${apiBase}/auth/forgot-password`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request)
        })
    } catch (e) {
        throw i18n.t("common.error.500");
    }
    if (result.status === 200) {
        return true;
    }
    throw i18n.t("common.error.500");
}

export interface ResetPasswordRequest {
    token: string;
    newPassword: string;
    confirmPassword: string;
}

export const resetPassword = async (request: ResetPasswordRequest): Promise<boolean> => {
    let result;
    try {
        result = await fetch(`${apiBase}/auth/reset-password`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request)
        })
    } catch (e) {
        throw i18n.t("common.error.500");
    }
    if (result.status === 200) {
        return true;
    }
    throw i18n.t("common.error.500");
}

export interface ChangeEmailRequest {
    newEmail: string;
}

export interface ConfirmEmailChangeRequest {
    token: string;
}

export interface ConfirmEmailChangeResult {
    newEmail: string;
}

export interface ChangeLanguageRequest {
    language: string;
}

export const changeEmail = async (request: ChangeEmailRequest): Promise<ApiResponse<boolean>> => {
    return await fetchUsingApi(`${apiBase}/auth/change-email`, 'POST', () => true, JSON.stringify(request));
}

export const confirmEmailChange = async (request: ConfirmEmailChangeRequest): Promise<ApiResponse<ConfirmEmailChangeResult>> => {
    return await fetchUsingApi(`${apiBase}/auth/confirm-email-change`, 'POST', (e) => e as ConfirmEmailChangeResult, JSON.stringify(request));
}

export const changeLanguage = async (request: ChangeLanguageRequest): Promise<ApiResponse<boolean>> => {
    return await fetchUsingApi(`${apiBase}/auth/change-language`, 'POST', () => true, JSON.stringify(request));
}
