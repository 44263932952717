import {ReactNode} from "react";
import {Product} from "@/api/rest/api.ts";
import ManagedPopup from "@/components/common/ManagedPopup.tsx";
import {Document} from "@/api/rest/fileApi.ts";
import MaintenanceOverview from "@/components/household-contents/MaintenanceOverview.tsx";

export default function MaintenanceButton({children, className, product, documents, updateHousehold}: {children: ReactNode, className?: string, product: Product, documents: Document[], updateHousehold: () => void}) {
    return (
        <ManagedPopup trigger={children}
                      triggerClassname={className}
                      title={
                          <>
                              <span className='break-all'>{product.maintenanceName}</span>
                              <div className="flex-grow"></div>
                          </>
                      }
                      onClose={updateHousehold}
        >
            <MaintenanceOverview product={product} documents={documents} updateHousehold={updateHousehold}/>
        </ManagedPopup>
    )
}
