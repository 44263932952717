import {DocumentTextIcon, FolderIcon} from "@heroicons/react/24/outline";
import {useMemo} from "react";
import {Disclosure, Transition} from "@headlessui/react";
import {clsx} from "clsx";
import DocumentsPreview from "@/components/household-contents/DocumentsPreview.tsx";
import {Document, downloadDocument2, getDocumentUrl2} from "@/api/rest/fileApi.ts";

export default function DocumentsList({documents, buildingId, householdId, itemsClassName, onSelect, allowDownload = true, allowPreview = false, allowDelete = false, updateHousehold}: { documents: Document[], buildingId?: number, householdId?: number, itemsClassName?: string, onSelect?: (doc: Document) => void, allowDownload?: boolean, allowPreview?: boolean, allowDelete?: boolean, updateHousehold?: () => Promise<void>}) {

    const documentFolders = useMemo(() => {
        const rootFolder: DocumentFolder = {folders: new Map(), documents: []};
        documents.forEach(document => {
            const path = document.folderPath;
            let currentFolder = rootFolder;
            for (let i = 0; i <= path.length; i++) {
                if (i === path.length) {
                    currentFolder.documents.push(document);
                } else {
                    if (!currentFolder.folders.has(path[i])) {
                        currentFolder.folders.set(path[i], {folders: new Map(), documents: []});
                    }
                    currentFolder = currentFolder.folders.get(path[i])!;
                }
            }
        });
        if (rootFolder.folders.size === 1 && rootFolder.documents.length === 0) {
            return rootFolder.folders.values().next().value as DocumentFolder;
        }
        return rootFolder;
    }, [documents]);

    return (
        <FolderContent folder={documentFolders} buildingId={buildingId} householdId={householdId} itemsClassName={itemsClassName} allowPreview={allowPreview} allowDelete={allowDelete} updateHousehold={updateHousehold} onSelect={(doc) => {
            if (onSelect) {
                onSelect(doc)
            } else if (allowDownload) {
                downloadDocument2(getDocumentUrl2(doc), doc.originalFilename).then(null)
            }
        }} />
    )
}

const FolderContent = ({folder, buildingId, householdId, itemsClassName = "bg-white", onSelect, allowPreview, allowDelete, updateHousehold}: { folder: DocumentFolder, buildingId?: number, householdId?: number, itemsClassName?: string, onSelect: (doc: Document) => void, allowPreview: boolean, allowDelete: boolean, updateHousehold?: () => Promise<void>}) => {

    const [subfolders, documents] = useMemo(() => {
        const subfolders = Array.from(folder.folders.entries()).toSorted(([a], [b]) => a.localeCompare(b, undefined, {numeric: true, sensitivity: 'base'}));
        const documents = folder.documents.toSorted((a, b) => a.fileName.localeCompare(b.fileName, undefined, {numeric: true, sensitivity: 'base'}));
        return [subfolders, documents] as const;
    }, [folder]);

    const itemsClass = clsx("py-2 px-4 font-semibold text-md rounded-md overflow-clip hover:bg-orange-100 text-start cursor-pointer", itemsClassName);
    return (
        <div className="flex flex-col gap-2">
            {documents.map(document => (
                <DocumentsPreview
                    key={document.fileName}
                    buildingId={buildingId}
                    householdId={householdId}
                    className={itemsClass}
                    allowPreview={allowPreview}
                    document={document}
                    onSelect={() => onSelect(document)}
                    allowDelete={allowDelete}
                    updateHousehold={updateHousehold}>
                        <span>
                            <DocumentTextIcon className="h-5 w-5 -mt-1 inline-block mr-3"/>
                            {document.fileName}
                        </span>
                </DocumentsPreview>
            ))}
            {subfolders.map(([folderName, subFolder]) => (
                <Disclosure key={folderName}>
                    <Disclosure.Button className={itemsClass}>
                        <FolderIcon className="h-5 w-5 -mt-1 mr-3 inline-block"/>
                        {/*<FolderOpenIcon className="h-5 w-5 -mt-1 mr-3 hidden ui-open:inline-block"/>*/}
                        {folderName}
                    </Disclosure.Button>

                    <Transition
                        enter="transition duration-150 ease-in"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition duration-150 ease-out"
                        leaveFrom="opacity-100"
                        leaveTo=" opacity-0"
                    >
                        <Disclosure.Panel className="flex flex-col gap-2 ml-6">
                            <FolderContent folder={subFolder} buildingId={buildingId} householdId={householdId} itemsClassName={itemsClassName} onSelect={onSelect} allowPreview={allowPreview} allowDelete={allowDelete} updateHousehold={updateHousehold}/>
                        </Disclosure.Panel>
                    </Transition>
                </Disclosure>
            ))}
        </div>
    )
}

interface DocumentFolder {
    folders: Map<string, DocumentFolder>;
    documents: Document[];
}
