import {useNavigate, useParams} from "react-router-dom";
import {useWarrantyCasesBuilding} from "@/api/hooks/useWarrantyCases.ts";
import ListClaims from "@/components/warranty-contents/ListClaims.tsx";
import {useAccount} from "@/api/hooks/useAccount.ts";
import {useMemo} from "react";
import {useTranslation} from "react-i18next";

export default function BuildingPage() {
    const { t } = useTranslation()
    const {buildingId} = useParams<{ buildingId: string }>()
    const navigate = useNavigate()

    const account = useAccount()
    const [cases, ] = useWarrantyCasesBuilding(Number(buildingId))

    const currentBuilding = useMemo(() => {
        return account?.accounts.flatMap(a => a.managedBuildings).find(b => b.id === Number(buildingId))
    }, [account, buildingId])

    return (
        <main
            className="flex flex-grow flex-col p-4 md:p-12 md:overflow-y-auto scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-white items-center">
            <div className="flex flex-grow self-stretch flex-col">
                <div className="flex flex-row ">
                    <h2 className="text-3xl font-bold text-center mr-4">{currentBuilding?.street}</h2>
                </div>
                <div className="flex flex-col lg:flex-row gap-8 mt-8">
                    <div className="flex flex-grow basis-1/2 flex-col gap-4 items-stretch">
                        <ListClaims cases={cases ?? []} showHouseholds={true}/>
                    </div>
                    <div className="flex flex-grow basis-1/2 flex-col gap-4">
                        <h3 className="text-xl font-semibold">{t("building.options")}</h3>
                        <button className="rounded-md py-2 px-4" onClick={() => navigate(`/building/${buildingId}/settings`)}>{t("building.settings")}</button>
                    </div>
                </div>
            </div>
        </main>
    )
}
