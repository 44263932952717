import {ReactNode} from "react";
import {clsx} from "clsx";
import UnmanagedPopup from "@/components/common/UnmanagedPopup.tsx";
import {useDialogue} from "@/api/hooks/useDialogue.ts";

export default function ManagedPopup({cleanup, trigger, triggerClassname, title, children, minHeight, onClose}: {
    trigger: ReactNode,
    title: ReactNode,
    children: ReactNode,
    triggerClassname?: string,
    cleanup?: () => void,
    minHeight?: boolean,
    onClose?: () => void,
}) {
    const [isOpen, setIsOpen] = useDialogue(cleanup);

    return (
        <UnmanagedPopup trigger={
            <span className={clsx("cursor-pointer", triggerClassname)} onClick={() => setIsOpen(true, true)}>
                {trigger}
            </span>
        } title={title} isOpen={isOpen} closePopup={() => {
            setIsOpen(false, true)
            onClose && onClose()
        }} minHeight={minHeight}>
            {children}
        </UnmanagedPopup>
    );
}
