import {FormEvent, useState} from "react";
import {resetPassword, ResetPasswordRequest} from "@/api/rest/authApi.ts";
import {BannerState} from "@/app/account/AccountPage.tsx";
import {clsx} from "clsx";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function ResetPasswordForm({className}: {className?: string}) {

    const { t } = useTranslation()
    const navigate = useNavigate();
    const { search} = useLocation();
    const token = new URLSearchParams(search).get('token');
    const [resetResult, setResetResult] = useState<BannerState | null>(null);

    const submitForm = async (e: FormEvent<HTMLFormElement>) => {
        setResetResult(null);
        e.preventDefault();
        const data = Object.fromEntries((new FormData(e.currentTarget)).entries()) as unknown as ResetPasswordRequest; // TODO: proper form validation
        if (!data.newPassword || data.newPassword !== data.confirmPassword) {
            setResetResult({message: t("account.passwordsNotSame"), type: "error"});
            return;
        }
        if (!token) {
            setResetResult({message: t("login.recoveryFormNotValid"), type: "error"});
            return;
        }
        data.token = token
        try {
            const result = await resetPassword(data)
            if (result) {
                setResetResult({type: "success", message: t("login.resetPasswordSuccess")});
            }
        } catch (e) {
            setResetResult({type: "error", message: String(e)});
        }
    }

    const goToLogin = () => {
        navigate('/login')
    }

    return (
        <form className={className} onSubmit={submitForm}>
            {resetResult?.type !== "success" && <input className="border border-gray-300 rounded-md p-2" type="password" name="newPassword"
                   autoComplete="new-password" placeholder={t("login.newPassword")} required/>}
            {resetResult?.type !== "success" && <input className="border border-gray-300 rounded-md p-2" type="password" name="confirmPassword"
                   autoComplete="new-password" placeholder={t("login.confirmNewPassword")} required/>}
            {resetResult && <span
                className={clsx(`rounded-lg p-4 text-center text-md`,
                    resetResult.type === "success" ? `bg-green-300 text-green-900` : `bg-red-300 text-red-900`
                )}>{resetResult.message}</span>}
            {resetResult?.type !== "success" && <button className="rounded-md p-2">{t("login.confirmPassword")}</button>}
            {resetResult?.type === "success" && <button className="rounded-md p-2" onClick={goToLogin}>{t("login.login")}</button>}
        </form>
    )
}
