import {ReactNode} from "react";
import {Product} from "@/api/rest/api.ts";
import {useNavigate} from "react-router-dom";
import ManagedPopup from "@/components/common/ManagedPopup.tsx";
import {Document} from "@/api/rest/fileApi.ts";
import {useTranslation} from "react-i18next";
import ProductOverview from "@/components/household-contents/ProductOverview.tsx";

export default function ProductButton({children, className, product, documents, updateHousehold}: {children: ReactNode, className?: string, product: Product, documents: Document[],
    updateHousehold: () => void}) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <ManagedPopup trigger={children}
                      triggerClassname={className}
                      title={
                          <>
                              <span className='break-all'>{product.name}</span>
                              <a className="rounded text-sm leading-6 px-2 mx-2 bg-gray-500 text-slate-100 hover:bg-dobu-orange-focus focus-visible:bg-dobu-orange-focus cursor-pointer self-baseline"
                                 onClick={() => navigate(`/household/${product.householdId}/product/${product.id}`)}>
                                  {t("product.change")}
                              </a>
                              <div className="flex-grow"></div>
                          </>
                      }
        >
            <ProductOverview product={product} documents={documents} updateHousehold={updateHousehold}/>
        </ManagedPopup>
    )
}
