import {apiBase} from "@/api/rest/api.ts";
import i18n from "../../i18n";

export interface EmitterData {
    tag: EmitterDataTag,
    data: string,
}

export enum EmitterDataTag {
    DOCUMENTS = "DOCUMENTS",
}

export const askQuestion = async (householdId: number, question: string, startFresh: boolean, streamResponse: (response: string, isComplete: boolean, tagsFound: EmitterData[]) => void): Promise<void> => {
    const result = await fetch(`${apiBase}/chat/v1/question`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            question,
            householdId,
            startFresh,
        })
    });
    const reader = result.body?.getReader();
    let response = '';
    const foundTags: EmitterData[] = []
    while (reader) {
        const { done, value } = await reader.read();
        if (done) {
            streamResponse(response, true, foundTags);
            return ;
        }
        const decodedResponse = new TextDecoder().decode(value);
        const emitterData = getEmitterData(decodedResponse);
        if (emitterData) {
            foundTags.push(emitterData);
        } else {
            response += decodedResponse;
        }
        streamResponse(response, false, foundTags);
    }
    throw i18n.t("common.error.500");
}

const emitterRegex = /^\[.+?:.+\]$/g
const getEmitterData = (response: string): EmitterData | null => {
    if (emitterRegex.test(response)) {
        const [tag, data] = response.slice(1, -1).split(':')
        return {
            tag: tag as EmitterDataTag,
            data: data
        }
    }
    return null;
}
