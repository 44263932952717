import {useCallback, useEffect, useState} from "react";
import {fetchHouseholdData, HouseholdData} from "../rest/api.ts";

export const useHousehold = (householdId: number | null) => {
    const [householdData, setHouseholdData] = useState<HouseholdData>();

    const fetchData = useCallback(async () => {
        if (!householdId) return;
        const data = await fetchHouseholdData(householdId);
        if (data.success) {
            setHouseholdData(data.result);
        }
    }, [householdId]);

    useEffect(() => {
        fetchData().then(null);
    }, [fetchData, householdId]);

    return [householdData, fetchData] as const;
}
