import {EntityConfiguration, EntityConfigurationType, updateConfiguration} from "@/api/rest/configurationApi.ts";
import {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

export default function ConfigurationEditor({conf, refreshConf}: {conf: EntityConfiguration, refreshConf: () => void}) {
    const [newConf, setNewConf] = useState(conf.value)
    const isDirty = newConf !== conf.value
    const { t } = useTranslation()

    const saveConf = useCallback(async (newConfValue: string) => {
        const result = await updateConfiguration({
            buildingId: conf.entityId,
            configurationType: conf.type,
            configurationValue: newConfValue
        })
        if (result.success) {
            refreshConf()
        }
    }, [conf, refreshConf])

    const getConfTypeName = (type: EntityConfigurationType): string => {
        switch (type) {
            case EntityConfigurationType.WELCOME_EMAIL:
                return t("building.welcomeEmail")
            case EntityConfigurationType.WARRANTY_PROMPT:
                return t("building.warrantyRules")
            default:
                return type
        }
    }

    return (
        <div className="flex flex-col gap-4">
            <span className='flex flex-row gap-2'>
                <h3 className="font-bold inline-flex">{getConfTypeName(conf.type)}</h3>
                {isDirty && <button className="rounded px-2" onClick={() => saveConf(newConf)}>{t("settings.save")}</button>}
            </span>
            <textarea value={newConf} onChange={e => setNewConf(e.target.value)} className="w-full min-h-[20vh] p-2 rounded"/>
        </div>
    )
}
